import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import SecureLS from 'secure-ls'
import router from '@/router'
import { notification } from 'ant-design-vue'
import { Tenant } from '@/types/Tenant'

const ls = new SecureLS({ isCompression: false })

export const authStore = defineStore('auth', () => {
  const token = ref(ls.get('token') || null)
  const user = ref(ls.get('user') || null)
  const loggedIn = computed(() => !!token.value)
  const buildingIds = ref(ls.get('buildingIds') || [])
  const multipleObjects = ref(ls.get('multipleObjects') || false)

  function login (username:string, password:string) {
    return new Promise<void>((resolve, reject) => {
      axios.post(process.env.VUE_APP_API_URL + '/login', {
        username: username,
        password: password
      }).then((res) => {
        // Set authenticated user.
        user.value = res.data.user
        ls.set('user', user.value)

        // Set token.
        token.value = 'Bearer ' + res.data.access_token
        ls.set('token', token.value)

        // Set buildings.
        ls.set('buildings', res.data.buildings)
        buildingIds.value = res.data.buildings.map((b:{ id:number }) => b.id)
        ls.set('buildingIds', res.data.buildings.map((b:{ id:number }) => b.id))
        multipleObjects.value = res.data.buildings.length > 1
        ls.set('multipleObjects', res.data.buildings.length > 1)

        // Set bearer token for axios calls.
        axios.defaults.headers.common.Authorization = token.value
        resolve()
      }).catch((e) => {
        reject(e)
      })
    })
  }

  function logout (forcedLogout = false) {
    axios.post(process.env.VUE_APP_API_URL + '/logout').finally(() => {
      // Clear token and localstorage.
      token.value = null
      ls.clear()

      // Remove bearer token for axios calls.
      delete axios.defaults.headers.common.Authorization

      if (forcedLogout) {
        router.push('/').then(() => {
          user.value = null
          notification.info({
            message: 'Sessie verlopen!.',
            description: 'Uw sessie is verlopen en u bent automatisch uitgelogd. Om verder te gaan kunt u opnieuw inloggen.',
            duration: 5
          })
        })
      } else {
        router.push('/').then(() => {
          user.value = null
          notification.success({
            message: 'Succesvol uitgelogd.',
            description: 'Uw bent succesvol uitgelogd. U kunt dit scherm nu sluiten.',
            duration: 5
          })
        })
      }
    })
  }

  function updateUser (userToUpdate:{ id:number }) {
    return new Promise<void>((resolve, reject) => {
      axios.post(process.env.VUE_APP_API_URL + '/users/details', userToUpdate).then((r) => {
        user.value = r.data.user
        ls.set('user', user.value)
        resolve()
      }).catch((e) => {
        reject(e)
      })
    })
  }

  function updateTenant (tenantToUpdate:Tenant) {
    return new Promise<void>((resolve, reject) => {
      axios.patch(process.env.VUE_APP_API_URL + '/tenants/' + tenantToUpdate.id, tenantToUpdate).then(() => {
        axios.get(process.env.VUE_APP_API_URL + '/users/tenants').then((r) => {
          user.value.tenants = r.data
          ls.set('user', user.value)
          resolve()
        }).catch((e) => {
          reject(e)
        })
      }).catch((e) => {
        reject(e)
      })
    })
  }

  return { user, loggedIn, buildingIds, multipleObjects, login, logout, updateUser, updateTenant }
})
